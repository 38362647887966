import { Component, OnInit } from '@angular/core';

function _window() : any {
  // return the global native browser window object
  return window;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
}

scrollToIdRef(element): void {

  element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
}
}
