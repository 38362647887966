import { ViewChild, Component, OnInit, HostListener } from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor() {}
  private open = false;


  ngOnInit(): void {
  }

  onNavOpen() {
    if(this.open) { this.sidenav.close(); this.open = false; }
    else { this.sidenav.open(); this.open = true; }
  }
}
