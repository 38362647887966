// src/app/components/login-button/login-button.component.ts

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.css']
})
export class LoginButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
