<!-- home section -->
<section class="home">
    <img src="assets/images/sensorbox-logo.svg" class="sensorbox-logo"/>
    <h3 class="wow fadeInUp sensorbox-intro-text" data-wow-delay="0.6s">A cloud-based <strong>IoT platform</strong> to build <strong>low-cost, long-range, decentralized</strong> sensor solutions</h3>
    <a (click)="scrollToIdRef(contactform)" class="btn btn-lg btn-success" >Contact us</a>
</section>

<!-- about section -->
<section class="about-container">
    <div class="about-content">
      <div>
        <img src="assets/images/dashboard-example.png" class="dashboard-image" alt="About">
      </div>
      <div class="section-title">
        <h3>Out-of-the-box dashboards</h3>
        <div>
          <p>With our dashboards we give users full insight of their
            operational sensors both on desktop and mobile.
            Historical data is queryable with intuitive charts on our
            scalable cloud infrastructure, which is able to connect millions of devices.

          </p>
        </div>
      </div>
    </div>
</section>


<!-- feature section -->
<section id="feature">
      <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="100" width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" class="svgcolor-light">
        <path d="M0 0 L50 100 L100 0 Z"></path>
      </svg>
<div class="feature-list-container">
  <div class="features-list">
    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            sensors
          </span>
          <h4 class="feature-label">Telemetry data</h4>
        </div>
        <div>
          <p>Collect and store your telemetry sensor data and access this data in our dashboards.</p>
        </div>
    </div>

    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            insights
          </span>
          <h4 class="feature-label">Data visualization</h4>
        </div>
        <div>
          <p>Visualize your sensor data in our advanced dashboards including charts, gauges, maps and compare different sensors over time.</p>
        </div>
    </div>

    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            notifications
          </span>
          <h4 class="feature-label">Alert management</h4>
        </div>
        <div>
          <p>Trigger alerts on device inactivity or other complex conditions for a predefined list of recipients.</p>
        </div>
    </div>
  </div>
  <div class="features-list">
    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            extension
          </span>
          <h4 class="feature-label">Integration</h4>
        </div>
        <div>
          <p>Integrate with external systems over MQTT(S) or via custom webhooks</p>
        </div>
    </div>

    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            cloud_done
          </span>
          <h4 class="feature-label">Cloud solution</h4>
        </div>
        <div>
          <p>Easily connect your sensors with our cloud platform, which takes away all kinds of challenges like scalability, redundancy and security concerns.</p>
        </div>
    </div>

    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            calculate
          </span>
          <h4 class="feature-label">Rule engine</h4>
        </div>
        <div>
          <p>With our rule engine we are able to build smart solutions. Maybe you would like to automate your systems by correlating different sensors with each other.</p>
        </div>
    </div>
  </div>

  <div class="features-list--mobile">
    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            sensors
          </span>
          <h4 class="feature-label">Telemetry data</h4>
        </div>
        <div>
          <p>Collect and store your telemetry sensor data and access this data in our dashboards.</p>
        </div>
    </div>

    <div class="feature-container">
        <div class="feature-header">
          <span class="material-symbols-outlined device-icon">
            insights
          </span>
          <h4 class="feature-label">Data visualization</h4>
        </div>
        <div>
          <p>Visualize your sensor data in our advanced dashboards including charts, gauges, maps and compare different sensors over time.</p>
        </div>
    </div>
  </div>

  <div class="features-list--mobile">
    <div class="feature-container">
      <div class="feature-header">
        <span class="material-symbols-outlined device-icon">
          notifications
        </span>
        <h4 class="feature-label">Alert management</h4>
      </div>
      <div>
        <p>Trigger alerts on device inactivity or other complex conditions for a predefined list of recipients.</p>
      </div>
  </div>

  <div class="feature-container">
    <div class="feature-header">
      <span class="material-symbols-outlined device-icon">
        extension
      </span>
      <h4 class="feature-label">Integration</h4>
    </div>
    <div>
      <p>Integrate with external systems over MQTT(S) or via custom webhooks</p>
    </div>
</div>
  </div>

  <div class="features-list--mobile">
    <div class="feature-container">
      <div class="feature-header">
        <span class="material-symbols-outlined device-icon">
          cloud_done
        </span>
        <h4 class="feature-label">Cloud solution</h4>
      </div>
      <div>
        <p>Easily connect your sensors with our cloud platform, which takes away all kinds of challenges like scalability, redundancy and security concerns.</p>
      </div>
  </div>

  <div class="feature-container">
      <div class="feature-header">
        <span class="material-symbols-outlined device-icon">
          calculate
        </span>
        <h4 class="feature-label">Rule engine</h4>
      </div>
      <div>
        <p>With our rule engine we are able to build smart solutions. Maybe you would like to automate your systems by correlating different sensors with each other.</p>
      </div>
  </div>
  </div>
  

</div>
      
</section>

<!-- contact section -->
<section id="contact" class="contact">

        <div class="sensoring-examples">
          <div class="section-title">
            <h3>Sensoring examples</h3>
            <p>We have some great examples of customers who are fully operational with our platform.</p>
          </div>
          <div class="carousel">
            <ul class="slides">
              <input type="radio" name="radio-buttons" id="img-1" checked />
              <li class="slide-container">
                <div class="slide-image">
                  <div class="usecase-title"> <span class="material-symbols-outlined device-icon">
                    water_damage
                    </span> <p class="usecase-label">Moisture</p></div>
                  <div class="usecase-content">
                    <p>To protect the art paintings of Het Haags Historisch museum we installed a couple of moisture sensors. When the sensors detect water they will immediately send a notification.</p>
                    <img class="museum-logo" src="assets/images/haags-historisch-museum-logo.png"/>
                  </div>
                </div>
                <div class="carousel-controls">
                  <label for="img-4" class="prev-slide">
                    <span>&lsaquo;</span>
                  </label>
                  <label for="img-2" class="next-slide">
                    <span>&rsaquo;</span>
                  </label>
                </div>
              </li>
              <input type="radio" name="radio-buttons" id="img-2" />
              <li class="slide-container">
                <div class="slide-image">
                  <div class="usecase-title"> <span class="material-symbols-outlined device-icon">
                    electric_meter
                    </span> <p class="usecase-label">Electricity</p></div>
                  <div class="usecase-content">
                    <p>In the port of Dordrecht they would like to know for each boat the electricity usage when the boats are docked.</p>
                    <img class="museum-logo" src="assets/images/port-of-dordrecht-logo.png"/>
                  </div>
                </div>
                <div class="carousel-controls">
                  <label for="img-1" class="prev-slide">
                    <span>&lsaquo;</span>
                  </label>
                  <label for="img-3" class="next-slide">
                    <span>&rsaquo;</span>
                  </label>
                </div>
              </li>
              <input type="radio" name="radio-buttons" id="img-3" />
              <li class="slide-container">
                <div class="slide-image">
                  <div class="usecase-title"> <span class="device-icon material-symbols-outlined">
                    gas_meter
                    </span> <p class="usecase-label">Gas</p></div>
                  <div class="usecase-content">
                    <p>At holiday resort Vrijrijck Vakantieparken we measure the gas usage for each residence. This gives the owner of the resort a good indication of the gas usage of their visitors.</p>
                    <img class="museum-logo" src="assets/images/vrijrijck-vakantiepark-logo.svg"/>
                  </div>
                </div>
                <div class="carousel-controls">
                  <label for="img-2" class="prev-slide">
                    <span>&lsaquo;</span>
                  </label>
                  <label for="img-4" class="next-slide">
                    <span>&rsaquo;</span>
                  </label>
                </div>
              </li>
              <input type="radio" name="radio-buttons" id="img-4" />
              <li class="slide-container">
                <div class="slide-image">
                  <div class="usecase-title"> <span class="material-symbols-outlined device-icon">
                    device_thermostat
                    </span> <p class="usecase-label">Temperature</p></div>
                  <div class="usecase-content">
                    <p>For the office building of Visser & Smit Bouw at Waalhaven Rotterdam, Sensorbox measures the incoming and outgoing water temperature. This warm water is from the nearby river and is responsibile for heating the building.</p>
                    <img class="museum-logo" src="assets/images/vissenensmitbouw-logo.jpeg"/>
                  </div>
                </div>
                <div class="carousel-controls">
                  <label for="img-3" class="prev-slide">
                    <span>&lsaquo;</span>
                  </label>
                  <label for="img-1" class="next-slide">
                    <span>&rsaquo;</span>
                  </label>
                </div>
              </li>
              <div class="carousel-dots">
                <label for="img-1" class="carousel-dot" id="img-dot-1"></label>
                <label for="img-2" class="carousel-dot" id="img-dot-2"></label>
                <label for="img-3" class="carousel-dot" id="img-dot-3"></label>
                <label for="img-4" class="carousel-dot" id="img-dot-4"></label>
              </div>
            </ul>
          </div>
        </div>
				<div class="section-title" #contactform>
					<h1 class="">Get in touch</h1>
					<p class="">Need more information or maybe a demo presentation? Do not hesitate to get in contact with us.</p>
         
          <table class="sensorbox-contact">
            <tr>
              <td class="contact-labels">📩 </td>
              <td><a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%69%6E%66%6F%40%73%65%6E%73%6F%72%2D%62%6F%78%2E%6E%6C">
                <span class="e-mail" data-user="ofni" data-website="ln.xob-rosnes"></span>
              </a></td>
            </tr>
            <tr>
              <td>📞 </td>
              <td>
                <a href="tel:+31853019732">+31853019732</a>
              </td>
            </tr>
          </table>
        </div>
</section>

<!-- footer section -->
<footer>
	<div class="footer-container">
		<div class="row">

      <svg class="svgcolor-light" preserveAspectRatio="none" viewBox="0 0 100 102" height="100" width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0 L50 100 L100 0 Z"></path>
      </svg>

      <div class="col-md-4 col-sm-6">
        <h2>mission</h2>
          <div>
             <p class="light-text">Sensorbox is an IoT platform to bring, in an accessible way, all kind of measured values (eg. temperature, kwh, co2, gas, water)
               to the cloud. This opens up all kind of technological possibilities, from combining sensor data with external systems to analyse sensor data over time.
             </p>
             
          </div>
      </div>

      <div class="sensorbox-address">
        <p class="light-text light-text--bold">
          Sensorbox VOF
        </p>
        <p class="light-text">
          Prins Hendrikstraat 57<br/>
          2291 EP Wateringen<br/>
          The Netherlands
        </p>
        <p class="copyright-text">Copyright &copy; 2022 Sensorbox <br>
        <ul class="social-icon">
          <li><a href="#" class="fa fa-facebook"></a></li>
          <li><a href="#" class="fa fa-twitter"></a></li>
          <li><a href="#" class="fa fa-behance"></a></li>
          <li><a href="#" class="fa fa-dribbble"></a></li>
        </ul>
      </div>

		</div>
	</div>
</footer>