import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class InterceptorTwo implements HttpInterceptor {
   constructor(public auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	  console.log('InterceptorTwo is working');
  
	 return from(this.auth.user$).pipe(
	 	switchMap((profile) => {
		 
		       const authReq = req.clone({
    headers: req.headers
        .set('X-WEBAUTH-USER', profile.email)
});
		 console.log(authReq);
		  return next.handle(authReq);
		})
	 );
    
  }
}
