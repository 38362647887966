import { Output, EventEmitter, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent implements OnInit {
  constructor() {}

  @Output() navOpen: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {}
  onClickMenu() {
	  console.log('emitter');
   this.navOpen.emit();
  }
}
