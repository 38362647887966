<mat-sidenav-container class="example-container" [hasBackdrop]="true">
  <mat-sidenav #sidenav mode="over">
    <div class="navbar-nav mr-auto">
 
<mat-nav-list class="sidenav-list">
  <a mat-list-item routerLink="/"
    class="navlink"
    routerLinkActive="router-link-exact-active"
    [routerLinkActiveOptions]="{ exact: true }"
    (click)="sidenav.close()"
  >
    <mat-icon>home</mat-icon>
    <span class="mat-list-item-label">Home</span>
  </a>
  <a mat-list-item
    class="navlink"
    href="https://portal.sensor-box.nl" target="_blank"
    routerLinkActive="router-link-exact-active"
    (click)="sidenav.close()"
  >
    <mat-icon>account_circle</mat-icon>
    <span class="mat-list-item-label">Go To Portal</span>
  </a>
  <!-- <a mat-list-item routerLink="/profile"
    class="navlink"
    routerLinkActive="router-link-exact-active"
    (click)="sidenav.close()"
  >
    <mat-icon>account_circle</mat-icon>
    <span class="mat-list-item-label">Profile</span>
  </a> -->
</mat-nav-list>
</div>

  </mat-sidenav>
  <mat-sidenav-content>
<div class="nav-container">
  <nav class="navbar navbar-light bg-light">
    <div class="container">
      <app-main-nav (navOpen)="sidenav.open()"></app-main-nav>
    </div>
  </nav>
    <router-outlet></router-outlet>
</div>
  </mat-sidenav-content>
</mat-sidenav-container>
